import img from "./assets/img.png";

function App() {
  return (
    <div className="">
      <div className="flex flex-col gap-6 bg-[url('./assets/harvard.avif')] h-screen items-center justify-center re opacity flter">
        <div className=" flex bg-black w-full text-white absolute top-0 items-center justify-between p-7">
          <p className="text-orange-500 text-xl">The Application Buddy</p>
          <div className=" hidden gap-5 justify-end items-end text-sm md:flex md:text-lg">
            <a className=" hover:text-orange-500" href="/">
              About Us
            </a>
            <a className=" hover:text-orange-500" href="/">
              Training Program
            </a>
            <a className=" hover:text-orange-500" href="/">
              Counselling Services
            </a>
            <a className=" hover:text-orange-500" href="/">
              Contact Us
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center p-10 md:p-16 md:gap-10 text-orange-500 bg-black rounded-xl">
          <p className="text-lg md:text-3xl lg:text-[64px] font-bold">
            Website Under Development
          </p>
          <p className="text-md md:text-2xl">- The Application Buddy</p>
        </div>
      </div>
    </div>
  );
}

export default App;
